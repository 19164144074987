import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 87px 6vw;
  align-items: center;
  padding-top: 90px;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 100px 4vw 0 4vw;
    padding: 0;
  }
`;

export const BoxText = styled.div`
  max-width: 40vw;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const EmailContent = styled.div`
  display: flex;
  margin-top: 27px;
  align-items: center;
  gap: 12px;

  a {
    color: var(--color-off-white);
    font-size: 14px;
    font-weight: 500;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: var(--color-tokenaim);
    }
  }
`;

export const BoxRegistration = styled.form`
  min-width: 38vw;

  select {
    font-family: Montserrat, sans-serif;
    color: #fafafa;
    background-color: #324455;
    width: 100%;
    border: 1px solid #324455;
    height: 56px;
    padding: 18px 30px 20px 15px;
    transition: border-color 0.25s ease;
    font-weight: 500;
    font-size: 14px;
    -webkit-appearance: none;
    background-image: url(/select-arrow.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) 50%;

    @media only screen and (max-width: 1200px) {
      margin-left: 0;
    }

    &:focus {
      border-color: #46dcaf;
      outline: none;
    }
  }

  textarea {
    background-color: #324455;
    width: 100%;
    color: #fafafa;
    height: 125px;
    font-family: Montserrat;
    font-weight: normal;
    border: 1px solid #324455;
    border-radius: 2px;
    padding: 20px 15px;
    resize: none;
    font-size: 14px;
    transition: border-color 0.25s ease;

    ::-webkit-input-placeholder {
      color: #fafafa;
    }
    
    :-moz-placeholder { /* Firefox 18- */
      color: #fafafa;  
    }
    
    :-moz-placeholder {  /* Firefox 19+ */
      color: #fafafa;  
    }
    
    ::-ms-input-placeholder {
      color: #fafafa;  
    }

    ::placeholder {
      color: #fafafa;
    }

    :focus {
      outline: none;
      border-color: #46dcaf;
    }
  }

  div {
    display: flex;
    width: 100%;
    gap: 8px;

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
      gap: 0;
      margin-bottom: 9px;
    }
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    width: 80%;
    margin-top: 40px;
    select {
      width: 100%;
    }
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 1200px) {
    button,
    a {
      width: 100%;
    }
  }
`;
