import styled from "styled-components";

export const Container = styled.div`
    margin: 0 6vw;
    margin-top: 60px;
    padding-top: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        margin: 0 2vw;
    }
`;

export const MessageIcon = styled.button`
    align-items: center;
    background-color: #46dcaf;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    height: 46px;
    justify-content: center;
    bottom: 4vh;
    right: 2vw;
    position: fixed;
    width: 46px;
    z-index: 3;

    img {
        width: 18.5px;
    }

    @media only screen and (max-width: 1023px) {
        right: 4vw;
    }
`;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BoxContainer = styled.div`
  max-width: 36vw;

  p {
    margin-top: 21px;
  }

  button {
    width: 16vw;
    margin-top: 34px;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 0 4vw;
    max-width: 100%;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }

    button {
      width: 195px;
    }
  }
`;

export const ImageContainer = styled.div`
  .image2 {
    position: absolute;
    right: 36vw;
    top: 407px;
  }

  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 50px 2vw;

    .image1 {
      width: 345px;
      height: 100%;
    }

    .image2 {
      width: 181px;
      position: static;
      margin-bottom: 15px;
    }
  }
`;
