import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: space-between;
  background-color: #1e2c39;
  padding: 85px 12vw;
  padding-top: ${(props) => (props.padding ? props.padding : "85px")};

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 50px 4vw;
    padding-top: ${(props) => (props.padding ? props.padding : "85px")};

    .image {
      width: 100%;
      height: 100%;
    }
  }
`;

export const BoxText = styled.div`
  max-width: 30vw;

  h1 {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 36px;
  }

  p {
    margin-bottom: 14px;
  }

  .paragraph-2 {
    margin-bottom: 25px;
  }

  button {
    margin-top: 20px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-top: 23px;

    button {
      width: 210px;
    }

    h1 {
      font-size: 24px;
      line-height: 29px;
    }
  }
`;

export const AdditionalContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  p {
    margin: 0;
  }

  img {
    margin-right: 12px;
  }
`;
