import { AdditionalContent, BoxText, Container } from "./styles";
import {
  Cartola,
  Title,
  Paragraph,
  Additional,
} from "../../components/Templates/texts";

import { ButtonPattern } from "../../components";

function Block08() {
  return (
    <>
      <Container id="marketplace">
        <img
          src="/image1-block8.png"
          alt="Garrafa NFT"
          loading="lazy"
          className="image"
          width="600px"
          height="600px"
        />
        <BoxText>
          <Cartola>Em breve Marketplace</Cartola>
          <Title>Colecionáveis e NFTs</Title>
          <Paragraph>
            Itens colecionáveis e objetos únicos. Monte sua galeria digital.
          </Paragraph>
          <Paragraph className="paragraph-2">
            Um marketplace para você registrar, comprar e vender NFTs de maneira
            fácil, rápida e segura.
          </Paragraph>
          <AdditionalContent>
            <img src="/check-icon.svg" width="14px" height="14px" alt="" loading="lazy"/>
            <Additional>Obras de arte</Additional>
          </AdditionalContent>
          <AdditionalContent>
            <img src="/check-icon.svg" width="14px" height="14px" alt="" loading="lazy"/>
            <Additional>Itens do metaverso</Additional>
          </AdditionalContent>
          <AdditionalContent>
            <img src="/check-icon.svg" width="14px" height="14px" alt="" loading="lazy"/>
            <Additional>Músicas e vídeos</Additional>
          </AdditionalContent>
          <AdditionalContent>
            <img src="/check-icon.svg" width="14px" height="14px" alt="" loading="lazy"/>
            <Additional>E muito mais!</Additional>
          </AdditionalContent>
          <ButtonPattern anchor="/#contato">Entre em contato</ButtonPattern>
        </BoxText>
      </Container>
      <Container direction="row-reverse" padding="0">
        <img
          src="/image2-block8.png"
          alt="Homem usando o notebook"
          loading="lazy"
          className="image"
          width="600px"
          height="600px"
        />
        <BoxText>
          <Cartola>Ecossistema blockchain</Cartola>
          <Title>Negocie seus tokens por meio da Bra.ex</Title>
          <Paragraph>
            A Braex foi desenvolvida para incentivar a atividade comercial
            segura e transparente e facilitar a negociação secundária de ativos
            por meio da rede Blockchain entre os usuários da 2nd Market.{" "}
          </Paragraph>
          <Paragraph color="#FFFFFF">
            Já estamos em fase de teste, aguarde!
          </Paragraph>
          <ButtonPattern anchor="/#contato">Pré-cadastro</ButtonPattern>
        </BoxText>
      </Container>
    </>
  );
}

export default Block08;
