export const Check = (props) => {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.31226 8.54687L7.46851 10.7031L11.0623 6.39062"
          stroke={props.white ? "#fff" : "#46dcaf"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.1875 15.375C12.157 15.375 15.375 12.157 15.375 8.1875C15.375 4.21795 12.157 1 8.1875 1C4.21795 1 1 4.21795 1 8.1875C1 12.157 4.21795 15.375 8.1875 15.375Z"
          stroke={props.white ? "#fff" : "#46dcaf"}
          strokeWidth="1.5"
        />
      </svg>
      <style jsx>{`
        svg {
          min-width: 17px;
        }
      `}</style>
    </>
  );
};

export const Prev = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 7.25012L16 7.25006"
        stroke="#8C96A0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.29996 12.0501L1.5 7.25012L6.29996 2.45016"
        stroke="#8C96A0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Next = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7.25012L1.5 7.25006"
        stroke="#8C96A0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2 12.0501L16 7.25012L11.2 2.45016"
        stroke="#8C96A0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
