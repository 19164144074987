import { ButtonPattern } from "../../components";
import { BoxContent, Container } from "./styles";

function Page404() {
	return (
		<Container>
			<BoxContent>
				<img
					src="/vector/404.svg"
					width="658"
					height="300"
					alt="Imagem 404"
					loading="lazy"
					style={{ width: "100%", maxWidth: "658px" }}
				/>
				<h1>Perdido?</h1>
				<p>
					A página que você está procurando não existe. Volte
					para o início e continue navegando no site da Tokenaim.
				</p>
				<ButtonPattern anchor="/">
					Voltar para o inicio
				</ButtonPattern>
			</BoxContent>
		</Container>
	);
}

export default Page404;
