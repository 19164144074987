import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const BlockContent = styled.div`
  min-width: 60vw;
`;

export const BoxText = styled.div`
  max-width: 48vw;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin: 145px 4vw 0 4vw;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const BoxIcons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 75px 0 162px 0;

  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 47px 0 95px 0;
  }
`;

export const ContentIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: #8c96a0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-top: 19px;
  }

  @media only screen and (max-width: 1200px) {
    align-items: center;
    margin-bottom: 26px;
    padding: 30px;
  }
`;

export const IconImage = styled.div`
  align-items: center;
  justify-content: center;
  background-color: #1e2c39;
  padding: 15px;
  border-radius: 100%;
  width: 60px;
  height: 60px;
`;
