import styled from "styled-components";

export const Container = styled.div`
  margin: 170px 6vw;
  max-width: 46vw;
  padding-top: 90px;

  h1 {
    margin: 0;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin: 95px 4vw;
    padding: 0;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const CardsContent = styled.div`
  display: flex;
  margin-top: 75px;
  min-width: 88.5vw;

  .swiper {
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .swiper-container {
    width: 100%;
  }

  @media screen and (min-width: 640px) {
    .swiper-container {
      width: 640px;
    }
  }

  @media screen and (min-width: 768px) {
    .swiper-container {
      width: 768px;
    }
  }

  .swiper-slide {
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 5px;
`;

export const Button = styled.button`
  display: flex;
  background-color: #1e2c39;
  padding: 13px;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  transition: border-color 0.25s ease;
  border: 1px solid transparent;

  &:hover {
    border-color: #46dcaf;

    path {
      stroke: #46dcaf;
    }
  }
`;
