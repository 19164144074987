import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 170px 12vw;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    margin: 95px 2vw;
    flex-direction: column;
  }
`;

export const BoxText = styled.div`
  max-width: 32vw;

  p {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  button {
    width: 14vw;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin: 0 2vw;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }

    button {
      width: 165px;
    }
  }
`;

export const BoxInfos = styled.div`
  width: 38vw;
  border: 1px solid #324455;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const BoxSelects = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;

  select {
    background-color: #1e2c39;
    padding: 20px 15px;
    font-size: 14px;
    color: #8c96a0;
    margin-bottom: 9px;
    border: 1px solid #324455;
    &:focus {
      border: 1px solid #46dcaf;
    }
  }

  span {
    color: #8c96a0;
    font-size: 16px;
    margin-bottom: 13px;
  }
`;

export const BoxToken = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e2c39;
  padding: 40px 0;
  margin-top: 4px;
  font-size: 14px;

  span {
    color: #fafafa;
  }

  p {
    color: #46dcaf;
    margin: 0;
  }
`;
