import { Cartola, Title, Paragraph } from "../../components/Templates/texts";
import { ButtonPattern } from "../../components";

import { Container, BoxContainer, ImageContainer } from "./styles";

function Block01() {
  return (
    <>
      <Container id="tokenize">
        <BoxContainer>
          <Cartola>Tokenization Services</Cartola>
          <Title>A tecnologia está mudando a maneira de fazer dinheiro</Title>
          <Paragraph>
            Agora ficou muito mais fácil criar ativos digitais
          </Paragraph>
          <ButtonPattern anchor="/#contato">Pré-cadastro</ButtonPattern>
        </BoxContainer>
        <ImageContainer>
          <img
            src="/image2-block1.png"
            className="image2"
            alt="Referência NFT"
            width="191"
            height="229"
          />
          <img
            src="/image1-block1.png"
            className="image1"
            alt="Referência NFT"
            width="452"
            height="405"
          />
        </ImageContainer>
      </Container>
    </>
  );
}

export default Block01;
