import styled from "styled-components";

export const Container = styled.div`
  margin: 0 6vw;
  padding-top: 90px;

  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    margin: 0 2vw;
    margin-top: 97px;
    padding: 0;
  }
`;

export const BoxTitle = styled.div`
  max-width: 53vw;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const BoxContent = styled.div`
  margin: 160px 6vw;
  display: flex;
  justify-content: space-between;
  gap: 61px;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 93px 2vw 50px 2vw;

    img {
      width: 299px;
      height: 299px;
      margin-right: -13vw;

      @media only screen and (max-width: 1200px) {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export const TextBox = styled.div`
  max-width: 30vw;

  h1 {
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 10px;
  }

  button {
    width: 14vw;
    margin-top: 12px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin: 28px 4vw 0 4vw;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    button {
      width: 165px;
    }
  }
`;
