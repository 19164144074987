import { Cartola, Title, Paragraph } from "../../components/Templates/texts";
import { BoxTitle, Container, BoxContent, TextBox } from "./styles";
import { ButtonPattern } from "../../components";

function Block06() {
  return (
    <Container id="custodia">
      <BoxTitle>
        <Cartola>Serviço de custódia</Cartola>
        <Title>
          Segurança em nível institucional com uma infraestrutura ultrarrápida
        </Title>
      </BoxTitle>
      <BoxContent>
        <div>
          <img
            src="/image-block6.png"
            loading="lazy"
            alt="Mulher usando um tablet"
            width="658px"
            height="600px"
          />
        </div>
        <TextBox>
          <Cartola>Imutabilidade e transparência</Cartola>
          <Title size="32px" height="36px" weight="600">
            Registro descentralizado e seguro na blockchain
          </Title>
          <Paragraph>
            A tecnologia blockchain proporciona o registro transparente de
            informações, à prova de adulteração.
          </Paragraph>
          <Paragraph>
            Após o smart contract ser registrado na rede blockchain, as
            informações passam a ser acessiveis publicamente a qualquer hora e
            de qualquer lugar do mundo.
          </Paragraph>
          <ButtonPattern anchor="/#contato">Entre em contato</ButtonPattern>
        </TextBox>
      </BoxContent>
    </Container>
  );
}

export default Block06;
