import {
  Container,
  BlockContent,
  BoxText,
  BoxIcons,
  IconImage,
  ContentIcon,
} from "./styles";
import { Cartola, Title } from "../../components/Templates/texts";
import { FadeInWhenVisible } from "../../components/Behavior";

function Block03() {
  return (
    <Container>
      <BlockContent>
        <BoxText>
          <Cartola>Ativos Tokenizáveis</Cartola>
          <Title>
            Alcance um novo público: Invista em ativos que antes não estavam
            disponíveis
          </Title>
        </BoxText>
        <BoxIcons>
          <FadeInWhenVisible delay="0.25">
            <ContentIcon>
              <IconImage>
                <img
                  src="/art-icon.svg"
                  width="30"
                  height="30"
                  alt="Paleta de cores"
                  loading="lazy"
                />
              </IconImage>
              <span>Obras de arte</span>
            </ContentIcon>
          </FadeInWhenVisible>
          <FadeInWhenVisible delay="0.5">
            <ContentIcon>
              <IconImage>
                <img
                  src="/horse-icon.svg"
                  alt="Cavalo de tabuleiro"
                  loading="lazy"
                  width="30"
                  height="30"
                />
              </IconImage>
              <span>Colecionáveis</span>
            </ContentIcon>
          </FadeInWhenVisible>
          <FadeInWhenVisible delay="0.75">
            <ContentIcon>
              <IconImage>
                <img
                  src="/home-icon.svg"
                  alt="Casa"
                  loading="lazy"
                  width="30"
                  height="30"
                />
              </IconImage>
              <span>Imobiliário</span>
            </ContentIcon>
          </FadeInWhenVisible>
          <FadeInWhenVisible delay="1">
            <ContentIcon>
              <IconImage>
                <img
                  src="/files-icon.svg"
                  alt="Arquivos na nuvem"
                  loading="lazy"
                  width="30"
                  height="30"
                />
              </IconImage>
              <span>Intangíveis</span>
            </ContentIcon>
          </FadeInWhenVisible>
          <FadeInWhenVisible delay="1.25">
            <ContentIcon>
              <IconImage>
                <img
                  src="/commodities-icon.svg"
                  alt="Carregando arquivos"
                  loading="lazy"
                  width="30"
                  height="30"
                />
              </IconImage>
              <span>Commodities</span>
            </ContentIcon>
          </FadeInWhenVisible>
        </BoxIcons>
      </BlockContent>
    </Container>
  );
}

export default Block03;
