import styled from "styled-components";

export const Container = styled.div`
  margin: 0 6vw;
  margin-top: 213px;

  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    margin: 0 4vw;
    margin-top: 45px;
  }

  .swiper {
    margin-top: 130px;

    @media only screen and (max-width: 1200px) {
      margin-top: 70px;
      width: 100%;
    }
  }

  .swiper-slide {
    text-align: center;
  }

  .swiper-wrapper {
    transition-timing-function: linear;
  }
`;

export const BoxTitle = styled.div`
  max-width: 54vw;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin: 0 4vw;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const BoxContent = styled.div`
  margin: 160px 6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 93px 2vw 0 2vw;

    .image {
      width: 100%;
      height: 100%;
    }
  }
`;

export const TextBox = styled.div`
  max-width: 30vw;
  margin-left: 30px;

  h1 {
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 10px;
  }

  button {
    width: 12vw;
    margin-top: 12px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin: 28px 4vw 0 4vw;

    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }
  }
`;

export const BoxAdditional = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 15px 0;
  }

  img {
    margin-right: 12px;
  }
`;

export const BoxCarrossel = styled.div`
  display: flex;
  justify-content: space-between;
`;
