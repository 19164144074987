import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 100px 0;

    @media only screen and (max-width: 1025px) {
      width: 100%;
      display: flex;
    }
`;

export const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  max-width: 50vw;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #fafafa;
    margin-bottom: 15px;
    margin-top: 50px;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
  }

  p {
    color: #fafafa;
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    max-width: 500px;
  }

  button {
    margin-top: 28px;
    width: 228px;
  }

  @media only screen and (max-width: 1025px) {
    max-width: 100%;

    h1, p {
      padding: 0 5vw;
    }

    button {
      width: auto;
    }
  }
`;