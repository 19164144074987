import React, { useRef } from "react";

import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

import { Container, CardsContent, ContainerButtons, Button } from "./styles";
import { Cartola, Title } from "../../components/Templates/texts";
import { Card } from "../../components";
import { Next, Prev } from "../../components/icons";

function Block05() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Container id="beneficios">
      <Cartola>Benefícios da tokenização</Cartola>
      <Title>
        O futuro é agora: a tecnologia está avançando e a Token.aim leva você
        até ela
      </Title>
      <CardsContent>
        <Swiper
          slidesPerView={isMobile ? 1 : 3}
          slidesPerGroup={1}
          loop={true}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <Card
              src="/liquidez-icon.svg"
              title="Liquidez"
              paragraph="A propriedade fracionada permite gerar liquidez a partir de um ativo ilíquido."
              alt="Mão entregando dinheiro"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              src="/contract-icon.svg"
              title="Contrato Inteligente"
              paragraph="Contrato autoexecutável registrado em uma rede blockchain, com total transparência de termos, condições e transações."
              alt="Papel de contrato"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              src="/clock-icon.svg"
              title="Rapidez nas Transações"
              paragraph="Devido à diminuição de intermediários, as redes blockchain permitem operações mais rápidas."
              alt="Relógio"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              src="/lock-icon.svg"
              title="Imutabilidade"
              paragraph="Cada token (ou fração) é único, exclusivo e com propriedade assegurada por uma rede blockchain imutável."
              alt="Cadeado trancado"
            />
          </SwiperSlide>
          <ContainerButtons>
            <Button ref={prevRef} aria-label="Anterior">
              <Prev />
            </Button>
            <Button ref={nextRef} aria-label="Próximo">
              <Next />
            </Button>
          </ContainerButtons>
        </Swiper>
      </CardsContent>
    </Container>
  );
}

export default Block05;
