import {
  Container,
  BoxTitle,
  BoxContent,
  TextBox,
  BoxAdditional,
} from "./styles";

import {
  Cartola,
  Title,
  Paragraph,
  Additional,
} from "../../components/Templates/texts";

import { ButtonPattern, CarrosselCard } from "../../components";

import { isMobile } from "react-device-detect";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

function Block02() {
  return (
    <Container>
      <BoxTitle>
        <Cartola>Redefinindo indústrias</Cartola>
        <Title>
          Destrave novas formas de liquidez, não se limite ao tradicional
        </Title>
      </BoxTitle>
      <Swiper
        slidesPerView={isMobile ? 3 : 7}
        spaceBetween={0}
        loop={true}
        speed={5000}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
        className="swiper"
      >
        <SwiperSlide>
          <CarrosselCard img="/binance.svg" name="Binance" alt="Logo Binance" />
        </SwiperSlide>
        <SwiperSlide>
          <CarrosselCard
            img="/avalanche.svg"
            name="Avalanche"
            alt="Logo Avalanche"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarrosselCard img="/polygon.svg" name="Polygon" alt="Logo Polygon" />
        </SwiperSlide>
        <SwiperSlide>
          <CarrosselCard img="/celo.svg" name="Celo" alt="Logo Celo" />
        </SwiperSlide>
        <SwiperSlide>
          <CarrosselCard
            img="/ethereum.svg"
            name="Ethereum"
            alt="Logo Ethereum"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarrosselCard img="/fanton.svg" name="Fanton" alt="Logo Fanton" />
        </SwiperSlide>
        <SwiperSlide>
          <CarrosselCard
            img="/polkadot.svg"
            name="Polkadot"
            alt="Logo Polkadot"
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarrosselCard img="/bankai.svg" name="Bankai" alt="Logo Bankai" />
        </SwiperSlide>
        <SwiperSlide>
          <CarrosselCard
            img="/ocean-protocol.svg"
            name="Ocean Protocol"
            alt="Logo Ocean Protocol"
          />
        </SwiperSlide>
      </Swiper>

      <BoxContent>
        <img
          src="/image-block2.png"
          alt="Homem usando o celular"
          loading="lazy"
          className="image"
          width="600px"
          height="600px"
        />
        <TextBox>
          <Cartola>Liquidez e Acessibilidade</Cartola>
          <Title size="32px" height="36px" weight="600">
            Modelo descentralizado e seguro: ativo real, título digital
          </Title>
          <Paragraph>
            Somos uma provedora de serviços de tokenização que realiza o
            desenvolvimento e o registro de smart contracts em redes Blockchain.
          </Paragraph>
          <Paragraph>
            Transforme seus ativos reais ilíquidos em ativos digitais facilmente
            comercializáveis, sem barreiras globais.
          </Paragraph>
          <BoxAdditional>
            <img src="/check-icon.svg" width="14px" height="14px" alt="" loading="lazy"/>
            <Additional>Emissão, custódia e distribuição</Additional>
          </BoxAdditional>
          <ButtonPattern anchor="/#contato">Fale conosco</ButtonPattern>
        </TextBox>
      </BoxContent>
    </Container>
  );
}

export default Block02;
