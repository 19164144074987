import styled from "styled-components";

export const Container = styled.div`
  margin: 0 6vw;
  padding-top: 90px;

  @media only screen and (max-width: 1200px) {
    padding: 0;
    margin: 0 4vw;
  }
`;

export const BoxTitle = styled.div`
  max-width: 45vw;
  margin-bottom: 75px;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const InfosContainer = styled.div``;
