import { useState } from "react";

import {
  Container,
  BoxText,
  EmailContent,
  BoxRegistration,
  ButtonContainer,
} from "./styles";

import {
  Cartola,
  Title,
  Paragraph,
  Strong,
} from "../../components/Templates/texts";
import { ButtonPattern, BaseInput, PhoneInput } from "../../components";
import { Api } from "../../api/api";
import { Check } from "../../components/icons";

function Block09() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageSent, setIsMessageSent] = useState(false);

  const submit = function (event) {
    event.preventDefault();

    setIsMessageSent(false);

    Api.submitContact({
      name: name,
      email: email,
      phone: phone,
      subject: event.target.querySelector('[name="subject"]').value,
      message: message,
    }).then(function (response) {
      setIsMessageSent(true);
    });

    return false;
  };

  return (
    <Container id="contato">
      <BoxText>
        <Cartola>Cadastre-se</Cartola>
        <Title>Conecte-se conosco para saber como podemos ajudar</Title>
        <EmailContent>
          <img src="/email-icon.svg" width="14px" height="11px" loading="lazy" alt=""/>
          <a href="mailto:contato@tokenaim.com.br" title="Fale conosco!">
            contato@tokenaim.com.br
          </a>
        </EmailContent>
      </BoxText>
      <BoxRegistration name="tokenaimLead" onSubmit={submit}>
        {isMessageSent && (
          <Paragraph
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "9px",
            }}
          >
            <Check />
            <Strong marginLeft="10px">Mensagem enviada!</Strong>
          </Paragraph>
        )}

        <BaseInput
          name="name"
          value={name}
          type="text"
          placeholder="Nome*"
          changeValue={setName}
        />
        <BaseInput
          name="email"
          value={email}
          type="email"
          placeholder="Email*"
          changeValue={setEmail}
        />
        <div>
          <PhoneInput
            type="phone"
            value={phone}
            name="phone"
            placeholder="Telefone"
            changeValue={setPhone}
          />
          <select
            name="subject"
            required
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          >
            <option value="pre-cadastro">Pré-cadastro</option>
            <option value="tokenizacao">Tokenização</option>
          </select>
        </div>
        <textarea
          name="message"
          placeholder="Mensagem"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <ButtonContainer>
          <ButtonPattern>Enviar contato</ButtonPattern>
        </ButtonContainer>
      </BoxRegistration>
    </Container>
  );
}

export default Block09;
