import { Container, BoxTitle, InfosContainer } from "./styles";
import { Cartola, Title } from "../../components/Templates/texts";
import { SectionInfos } from "../../components";

function Block04() {
  return (
    <Container id="como-funciona">
      <BoxTitle>
        <Cartola>Como funciona</Cartola>
        <Title>Crie smart contracts de maneira rápida e segura</Title>
      </BoxTitle>
      <InfosContainer>
        <SectionInfos
          title="Desenvolvimento"
          paragraph="Análise do ativo pela Token.aim e desenvolvimento de contratos automatizáveis de acordo com a necessidade do cliente."
          img="/conformidade-image.png"
          position="1"
          alt="Pesquisando"
        />
        <SectionInfos
          title="Emissão"
          paragraph="Os smart contracts são desenvolvidos pela Token.aim e registrados em uma rede Blockchain."
          align="row-reverse"
          img="/emissao-image.png"
          position="2"
          alt="Dados"
        />
        <SectionInfos
          title="Distribuição"
          paragraph="Pronto, os tokens podem ser ofertados publicamente dentro de uma exchange (Braex) ou podem ser enviados para um endereço indicado. A Braex faz parte do ecossistema da 2nd Market
e cumpre essa função."
          img="/distribuicao-image.png"
          position="3"
          alt="Transferência de informações"
        />
        <SectionInfos
          title="Implementação"
          paragraph="A Token.aim oferece treinamento para a implementação do smart contract pelo cliente."
          align="row-reverse"
          img="/governanca-image.png"
          position="4"
          alt="Aperto de mãos"
        />
        <SectionInfos
          title="Custódia"
          paragraph="Armazenamento das chaves criptográficas
que controlam esses ativos com hardware 
especializado, arquitetura de segurança 
avançada e processamento eficiente."
          img="/custodia-image.png"
          position="5"
          alt="Cofre"
        />
      </InfosContainer>
    </Container>
  );
}

export default Block04;
